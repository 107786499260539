.head {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 99999;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 70px;
    background-color: #FFF;
}

.header-name {
    display: inline-block;
    font-size: 17px;
    padding-top: 4px;
    color: #005AFF;
    text-align: center;
    /* font-weight: bold;
    text-transform: uppercase; */
    vertical-align: middle;
}



.header-icons {
    float: right;
    cursor: pointer;
    /* text-align: center; */
    margin-right: 8px;
    margin-left: 18px;
    margin-top: -10px;
}

.imgPosition{
    float: right;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 18px;
    margin-top: -100px;
}


.imageDrawer {
    width: 100%;
    position: relative;
    align-items: center;
}

.inputStyle {
    width: 280px !important;
    height: 30px;
    color: #868686;
    font-size: 16px;
    border-radius: 5px;
    border-color: #124191 !important;
    transition: width 0.4s ease;
}

.closedInputStyle{
    height: 30px;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    padding: 0!important;
    border-color: #124191 !important;
    transition: width 0.4s ease;
    width: 0px !important;
}


@media (max-width: 700px) {

    .closedInputStyle{
        max-width: 150px !important;
        height: 30px;
        color: white;
        font-size: 16px;
        border-radius: 5px;
        padding: 0!important;
        border-color: #124191 !important;
        transition: width 0.4s ease;
        width: 0px !important;
    }

    .inputStyle {
        max-width: 150px !important;
        height: 30px;
        color: #868686;
        font-size: 16px;
        border-radius: 5px;
        border-color: #124191 !important;
        transition: width 0.4s ease;
    }
}

.header-input {
    float: right;
    height: 50px;
    line-height: 50px;
    padding-right: 15px;
}

::-webkit-input-placeholder {
    color: #B7B7B7;
}

:-moz-placeholder {
    color: #B7B7B7;
    opacity: 1;
}

::-moz-placeholder {
    color: #B7B7B7;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #B7B7B7;
}

::-ms-input-placeholder {
    color: #B7B7B7;
}

.header-input input {
    width: 400px;
    padding: 5px 6px;
    font-size: 100%;
    border-left: 1px solid #D8D8D8;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    border-right: none;
    color: #666;
    line-height: 1;
}

.header-input input:focus {
    outline: none;
    border: 1px solid #B8B8B8;
}

.menu {
    background-color: #ffffff;
}

.drawer {
    background-color: #124191;
    padding: 0;
}

.topDrawer {
    height: 180px;
    width: 320px;
    background-color: #005AFF;
}


.header {
    width: 100%;
    height: 50px;
}

.ant-badge{
    top: -5px;
}
.ant-badge-count {
    min-width: 0px;
}  
.logo-title {
    display: inline-block;
    text-align: left;
    float: left;
    padding-top: 9px;
  }
  
.logo-title .logo {
      width: 100px;
      height: auto;
      display: inline-block;
      /* float: left; 
      padding-top: 16px; */
      margin-left: 24px;
      margin-right: 24px; 
      transition: all 0.5s ease 0s;
  }

.logo-title .logo img {
      max-width: 100%;
      height: auto;
      width: auto\9; /* ie8 */
  }

.logo-title .itemH {
    margin-left: 24px;
    margin-right: 24px;
    color: #005AFF;
    font-size: 18px;
    font-family: NokiaPureText-Light, Segoe, Tahoma, sans-serif;
    line-height: 50px;
    padding-top: 10px;
    position: absolute;
    top: -2px;
  }

  /* pipe removed for new visuals
.logo-title .pipe {
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    width: 2px;
    background-color: #005AFF;
  } */

@media screen and (max-width: 480px) {
    .logo-title .itemH {
        margin-left: 12px;
        margin-right: 12px;
        margin-top: -2px;
        font-size: 0.7em;
    }
    
    .logo-title .logo {
        width: 60px;
        margin-top: 3px;
    }

    .logo-title .pipe {
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        width: 2px;
      }

    .logo-title .logo{
        margin-left: 12px;
        margin-right: 12px;
    }

    .header-icons {
        width: 34px;
    }

    .inputStyle{
        position: relative;
        left: -155px;
        bottom: 52px;
    }

    .closedInputStyle{
        position: relative;
        left: -155px;
        bottom: 52px;
    }

    .askedu{
        display: none;
    }
}

.infoBadge{
    font-weight:'bold';
    right:'62px';
    height:'0px';
    line-height:'0px';
    padding:'5px';
    top:'-8px';
}

.infoIcon{
    color: #005AFF !important;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;

}

.infoText{
    z-index: 100000;
}

.infoText .ant-tooltip-inner{
    background-color: #ffffff;
    color: #124191;
    font-weight: bold;
}

.infoText .ant-tooltip-arrow{
    display: none;
}

/* button feedback */
.usrp-feedback-button-1 {
    position: fixed;
    top: 50%;
    right: 0;
    margin-top: -25px;
    max-width: 50px;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  /* Icon background */
  .usrp-feedback-button-1 i {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
  }
  /* Transitions */
  .usrp-feedback-button-1 i,
  .usrp-feedback-button-1 span {
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  /* Icon */
  .usrp-feedback-button-1 svg {
    width: 100%;
    height: 100%;
  }
  .usrp-feedback-button-1 svg .fill {
    fill: #ffffff;
  }
  /* Text */
  .usrp-feedback-button-1 span a {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 15px 0 65px;
    white-space: nowrap;
    background: #124191;
    color: #FFF;
  }
  /* Hover */
  .usrp-feedback-button-1:hover {
    max-width: 200px;
  }
  .usrp-feedback-button-1:hover i {
    background: rgba(0, 0, 0, 0.2);
  }
  .usrp-feedback-button-1:hover span {
    color: #ffffff;
  }

  /* Button*/
  .styleButton {
      color: #005AFF;
      margin-right: 10px;
      font-family: NokiaPureText-Regular !important;
      margin-top: 20px;    
    }

.headerIcons {
    float: right;
    cursor: pointer;
    text-align: center;
    margin-right: 12px;
    margin-left: 12px;
    }
    
.info-icon{
    color: #005AFF !important;
    vertical-align: middle;
    margin-right: 4px;
}
    

  /* end button feedback */