
.notFound-welcome{
    color: #000;
    font-size: 150%;
}
#iconSearchNF {
    font-size: 200px;
}
p {
    margin-bottom: 0em;
}
.container {
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 105%;
}
.all {
    display: inline-block;
    width: 40%;

}
#hpage {
    padding-top: 80px;
    background-color: #FFFFFF;
}

.center {
    margin: 0 auto;
    text-align: center;
}
.imgcenter{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: initial;
}
.f{
    position: absolute;
    bottom: 0%;
}
.c{
    margin: 55px 0;
}
h1{
    color : #124191;
}