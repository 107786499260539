.modalContainer {
    bottom: 0;
    position: absolute;
    border-radius: 0px!important;
}

.bannerText {
    color: white;
    font-family: NokiaPureText-Light;
    font-size: 15px;
}

.buttonBanner {
    color: white;
    background-color: #273142;
    border-width: 1px;
    font-family: NokiaPureText-Light;
    font-size: 15px;
    border-radius: 0px;
    border-color: white;
}

.buttonBanner:hover {
    color: #273142;
    background-color: white;
    border-radius: 0px;
    border-color: white;
}

.linkBanner {
    color: white;
    font-family: NokiaPureText-Light;
    font-size: 17px;
}

.linkBanner:hover {
    color: #ededed;
    font-family: NokiaPureText-Light;
    font-size: 17px;
}

