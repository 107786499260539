.searchResult {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 300px;
    text-align: center;
    padding-top: 10px;
}

.messageBox{
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  text-align: center;
  padding: 10px;
}

.notfound{
  padding: 15px;
}

.ant-table-wrapper{
    padding: 0px 20px;
    margin-bottom: 40px;
}

#loader {
    position: absolute;
    left: 40%;
    top: 190%;
    z-index: 1;
    border: 16px solid #e8e8e8;
    border-radius: 50%;
    border-top: 16px solid #15418f;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }