.collTitle ul{
    margin-left: 5%;
}

.nokiaFontTitle {
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.85)
}


.containTitle {
    position: relative;
    text-align: center;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.white h1{
    color: #ffffff !important;
    font-size: 2vw;
    font-weight: bold;
    padding-top: 10px;
}

.white div{
    background: rgba(211, 211, 211, 0.6);
}

.tagline{
    color: #000000 !important;
    font-size: 1.2em;
}

.white a{
    color: #124192;
}

.desc2{
    margin-top: 10px;
}

.nokiaFontTitle{
    margin-bottom: 30px;
}

.itemFieldBody{
    margin-bottom: 20px;
}

.sortElt{
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
}

.flatTitle h2{
    margin-bottom: 10px !important;
}

.flatTitle ul{
    margin-left: 10%;
}

.childCollection{
    width: 100%;
}

.closeButton{
    position: absolute;
    left: 115px;
    top: 15px;
    display: inline;
    background-color: #383838;
    color: white;
    z-index: 10;
}

.closeButton:hover{
    color: #383838;
    background-color: white;
    border-color: #383838;
}

.thumb{
    position: relative;
    /* top: -30px; */
}

.printButton{
    position : absolute;
    top: 65px;
    right: 190px;
    width: 140px !important;
    text-align: left !important;
}

.tableButton{
    position : absolute;
    top: 160px;
    right: 50px;
    width: 130px !important;
}

.printLoader{
    position: absolute;
    left: 47% !important;
    top: 25% !important;
}

.loadPrint{
    opacity: 0.3;
	-ms-filter: "alpha (opacity=75)";
	filter: alpha (opacity=50);
	-moz-background-clip:border;
	-moz-background-inline-policy:continuous;
	-moz-background-origin:padding;
	background:silver none repeat scroll 0 0;
}

.tableColl{
    width: 80%;
}

.format:hover{
    cursor: default;
}

.col{
    display: inline-block;
    margin-right: 10px;
}

.row .ant-switch-checked {
    background-color: grey !important;
}

.cartButton{
    position : absolute;
    top: 65px;
    right: 330px;
    /* width: 150px !important; */
    text-align: left !important;
}

.disabled{
    border-color: grey !important;
    color: grey !important;
    width: 100px !important; 
} 

.disabled:hover{
    border-color: grey !important;
    color: grey !important;
    background-color: inherit !important;
} 

@media screen and (max-width: 600px){
    .printButton{
        position: absolute;
        top: 70px;
        right: 15px;
        width: 110px !important;
        height: 20px !important;
        font-size: 0.6em;
    }

    .cartButton{
        position: absolute;
        top: 95px;
        right: 5px;
        width: 110px !important;
        height: 20px !important;
        font-size: 0.6em;
    }

    .collectionLink{
        font-size: 0.6em;
    }

}