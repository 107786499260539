.shopcart-head {
    border-bottom: 2px solid #e2e2e5;
    padding: 1%;
    margin-bottom: 2%;
    background:#fff;
    color: #124191;
}
.shopcart-head h2{
    color: #124191;
}
.shopcart-home {
    padding-top: 120px;
    padding-bottom: 100px;
    background-color: #FFFFFF;
}
.header-cart {
    cursor: pointer;
    position: fixed;
    top: 15px;
    left: 400px;
}
.btn-n {
    float: right;
    margin: 30px 10px 0px 0px;
}
.btn-p {
    margin: 30px 0px 10px 10px;    
}
.bordershare{    
    border-bottom: 2px solid #f2f2f2;
}
.label {
    color: #124191;
    margin: 2% 0;
    display: block;
    font-size: 1em;
}
.card {
    float: left;
    height: 110px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 5px;
    color: #5f6368;
    font-size: 14px;
    margin: 20px 5px;
    overflow: hidden;
    transition: box-shadow 135ms cubic-bezier(.4,0,.2,1),width 235ms cubic-bezier(.4,0,.2,1);
    vertical-align: top;
    width: 240px;
    z-index: 1;
    box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16);
}
#rclick {
    visibility: hidden;
}
.shopSelect {
    width: 700px;
}
h4 {
    color:  #124191;
}
.btnGreen,.btnGreen:hover,.btnGreen:active{
    border-color: #21ba45;
    color: #21ba45;
    margin-top: 2%; 
    width: 230px;
}

.recipient {
 width: 250px;
 float: right;
 margin-top: 25px;
 margin-right: 20px;
}

@media screen and (max-width: 600px){
    .shopcart-head{
        text-align: center;
    }

    .shopcart-home{
        padding-bottom: 150px;
    }
}