
.navigation {
    margin-top: 20px;
    font-size: 50px !important;
}

.textMenuDrawer {
    font-size: 17px !important;
    font-family: NokiaPureText-Light;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 30px;
    height: 30px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 30px;
    height: 30px;
}
.ant-drawer-wrapper-body{
    overflow-x: hidden!important;
    overflow-y: auto;
    height: 100%;
}
.lastItem{
    font-family: NokiaPureText-Light;
    font-size: 20px;
}