.footer {
    background: #E8E8E8;
    text-align: center;
    line-height: 40px;
    padding-top: 10px;
    width: 100%;
    color: rgb(139, 139, 139);
    position: absolute;
    bottom: -100px;
    margin-top: 1000px;
}

.footerLink {
    color: #124191;
}

.footerLink:hover {
    color: #185bcb;
}

