.moreItem{
    background:'red'; 
    width: 250px;
    height: 250px;
    background: aliceblue; 
    text-align: center;
    padding: 40px;
    color: blue; 
    cursor: pointer;
    font-family: NokiaPureText-Regular !important;
    font-size: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-style:solid; 
    border-color: lightgray; 
    border-width:thin;
}

.moreItem:hover {
    border: 2px solid blue;
  }

