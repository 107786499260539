.modalTags .ant-modal-body {
    padding-top: 5px;
    padding-bottom: 5px;
}

.modalTags .ant-divider-horizontal {
    margin-top: 3px;
    margin-bottom: 3px;
}

.modalTags .ant-modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
}

.modalTags .ant-table-wrapper {
    margin-bottom: 0px;
}

.modalTags .ant-modal-footer {
    height: 50px;
}

.modalTags td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.modalTags th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.searchLabel{
    display: inline-block;
    height: 35px;
    margin-bottom: 10px !important;
}

.emailLabel{
    width: 55%;
    margin-right: 30px;
    height: 35px;
}

.stringLabels{
    width: 55%;
    float: left;
    height: 40px;
    margin-top: 0;
    height: 35px;
}

.ant-modal-content{
    max-height: 80%;
    width: 100%;
}

.tagText {
    font-size: 12px;
    font-style: italic;
    width: 90%;
}

.noTagList{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    height: 316px;
    text-align: center;
}

.modalTitle {
    font-weight: bold;
}