/*************************************************************************************
	#leadership {   
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%; 
	font-family: NokiaPureText-Light, Arial, sans-serif;
	}
*************************************************************************************/

.leadershipTtl {
    float: left;
    font-size: 24px;
    margin: 8px 0px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}

.leadershipSpan {
    display: block;
    margin: 8px;
    padding: 8px;
    background-color: #fff;
    opacity: 0.8;
}

.containerImg {
    float: left;
    width: 200px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: 0%;
}

.containerImg img {
    width: 100%;
    height: 100%;
}

.containerBlue {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #124191
}

.containerGray {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #98a2ae
}

.containerYellow {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #fffb00
}

.containerGreen {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #4bdd33
}

.containerBlueblack {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #001135
}

.containerOrange {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #ff8b10
}

.containerAqua {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #00c9ff
}

.containerRed {
    float: left;
    width: 180px;
    height: 100px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #ff3154
}

p.titles {
    margin: 3px 0px;
    font-size: 16px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}

p.titles a {
    color: #124191;
    text-decoration: none
}

p.titles a:hover {
    text-decoration: underline
}

p.delivery {
    margin: 3px 0px;
    font-size: 11px;
    color: #001135;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}

hr {
    border: 0;
    border-bottom: 1px solid #98a2ae;
    background: #98a2ae;
}

.legendTxt {
    display: block;
    margin-left: 6px;
    font-size: 15px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
    margin-bottom: 5px;
    color: #808994
}

.note {
    float: right;
    padding: 14px 12px;
    background-color: #273142;
    font-size: 13px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}

.note a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.note a:hover {
    color: #ddd;
}

/************************************************************************************
tooltip css
*************************************************************************************/
.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 500px;
    background-color: #124191;
    color: #fff;
    font-size: 13px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
    text-align: left;
    border-radius: 12px;
    padding: 16px;
    position: absolute;
    border: 3px solid #fff;
    z-index: 1;
    top: -15px;
    left: 105%;
}

.tooltipUnused .tooltiptext::after { /* pointer arrow - unused */
    content: "";
    position: absolute;
    top: 15%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #124191 transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip {
    z-index: 100;
}