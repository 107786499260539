.select {
    width: 900px;
    margin: auto;
    text-align: center;
}

.home {
    background-image: url(./stars_black.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #fff;
    overflow: auto;
}

.storePellet {

    display: inline-block;
    vertical-align: top;
    width: 255px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.storePellet1 {
    background: rgba(223, 223, 223,0.7);
    width: 255px;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
}

.storePellet1_icon {
    margin-top: 15px;
}

.storePellet1_title {
    margin-top: 20px;
    font-size: 20px;
    color: #3d3d3d;
}

.storePellet2_icon {
    margin-top: 30px;
}

.storePellet2_subtitle {
    margin-top: 30px;
    font-size: 16px;
    color: #3d3d3d;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.storePellet2 {
    background: #124191;
    width: 255px;
    border-radius: 180px;
    height: 255px;
    box-sizing: border-box;
    text-align: center;
}

.selectTitle {
    font-size: 350%;
    text-align: center;
    color: #fff;
    padding-top: 110px;
    padding-bottom: 20px;
    margin-bottom: 110px;
    font-weight: 300;
}

.selectStore a {
    text-decoration: none;
}

.selectStore {
    margin: 50px 0px;
    background: rgba(255, 255, 255, 0.8);
}

.SelectStoreTitle {
    background-color: rgba(18, 65, 145, 0.7);
    text-decoration: none;
    font-size: 120%;
    color: #fff;
    padding: 10px 12px;
}

.SelectStoreBody {
    /* background-color: #fff; */
    padding: 10px 12px;
    color: #6f6f6f;
    font-size: 95%;
}

.SelectStoreButton {
    /* background-color: #fff; */
    text-decoration: none;
    font-size: 100%;
    color: #124191;
    text-align: right;
    padding: 10px 12px;
    /* border-radius: 0px 0px 5px 5px; */

}

.searchTags{
    margin-bottom: 10px;
    margin-top: -30px;
}

/*cindy css*/ 
a { color: #3569d6; text-decoration: none }
      
a:hover { color: #4c4b5e; text-decoration: none }

img { width: 100%; height: auto }
    
.heading {
    margin: 0;
    width: 75%;
    color: #071534;
    font-size: 24px;
    /* font-family: nokiapure_hlight; */
    }
 
.sectionWrap { width: 100%; /*65%*/ margin: 0 auto }

/* .row::after { content: "";  clear: both; display: table } */

/* For mobile phones: */
[class*="part"] { /*[class*="col-"]*/
  width: 100%;
  float: left;
  padding: 16px;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .part .col-s-1 {width: 8.33%;}
  .part.col-s-2 {width: 16.66%;}
  .part .col-s-3 {width: 25%;}
  .part .col-s-4 {width: 33.33%;}
  .part .col-s-5 {width: 41.66%;}
  .part .col-s-6 {width: 50%;}
  .part .col-s-7 {width: 58.33%;}
  .part .col-s-8 {width: 66.66%;}
  .part .col-s-9 {width: 75%;}
  .part .col-s-10 {width: 83.33%;}
  .part .col-s-11 {width: 91.66%;}
  .part .col-s-12 {width: 100%;}
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .part .col-1 {width: 8.33%;}
  .part .col-2 {width: 16.66%;}
  .part .col-3 {width: 25%;}
  .part .col-4 {width: 33.33%;}
  .part .col-5 {width: 41.66%;}
  .part .col-6 {width: 50%;}
  .part .col-7 {width: 58.33%;}
  .part .col-8 {width: 66.66%;}
  .part .col-9 {width: 75%;}
  .part .col-10 {width: 83.33%;}
  .part .col-11 {width: 91.66%;}
  .part .col-12 {width: 100%;}
}

/* Buttons text-arrow styling */
.middle > * {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0 0 12px 0;
  padding: 8px;
  white-space: nowrap;
  box-sizing: border-box; 
}

.middle a { 
  background: #005aff;
  border-radius: 6px;
 }

#links-col a:hover,.middle a:hover { color: #005aff; text-decoration: none; font-weight: bold; }

.middle .btnTxt {
  display: inline-block;
  width: 80%;
  margin: 0;
  padding: 0 0 0 20px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  /* font-family: nokiapure_hlight; */
}

.middle .btnImg {
  display: inline-block;
  width: 20%;
  margin: 0;
  padding: 0 15px 0 0;
  text-align: right;
}

.middle .btnImg img { width: 35px; height: 35px }

/* Links  styling */
#links-col {
  float: left;
  display: block;
  width: 50%;
  margin: 0;
  padding: 0;
}

#links-col .heading {
  margin: 12px;
  border-bottom: 1px solid #8d94a7;
  display: block;
  padding: 0px;
  text-align: left;
  font-size: 20px;
  color: #005aff;
  font-weight: bold;
}

#links-col a {
  margin: 5px 12px;
  display: block;
  width: 95%;
  padding: 0px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #000
}   

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .sectionWrap {width: 95%;}
}

@media (max-width: 1000px) {
.middle .btnImg {
  display: none;}
  .middle .btnTxt {
  font-size: 16px;}
  
  .heading {
    font-size: 18px;
    }

}
