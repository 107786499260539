/* .itemOverlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  ground: rgba(0, 0, 0, 0.70);
} */
.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
    z-index: 999999999;
}

.ui.modal {
    height: 65%;

}

@media (min-width : 100px) {
    .ui.modal{
        height: 100%;
    }
    /* Styles */
    }

.ui.modal > .content {
    height: 80%;
    overflow-y: auto;
    font-size: 1.1em;
}


.imageItem {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    z-index: 1;
    margin-bottom: 15px;
    max-height: 125px;
}

#itemFieldTitle {
    color: #124191;
    font-size: 110%;
    font-family: NokiaPureText-Light, 'Arial', sans-serif !important;
}



.otg {
    margin: 0 auto;
    width: 160px;
    font-size: smaller;
}
.ant-popover {
    z-index: 1000000!important;
    max-width: 480px;
}

.reportIssue {
    font-size: 72%;
    margin-right: 12px;
    text-decoration: underline;
    color: #124191;
}

.fontNokia {
    font-family: NokiaPureText-Light, 'Arial', sans-serif !important;
    font-size: 17px !important;
    min-height: 30px;
}

.buttonPrint {
    display: inline-block;
  font-size: 28px;
    float: right;
    margin-right: 40px;
}
.buttonReturn {
    display: inline-block;
    float: right;
    margin-left: 55px;
    margin-top: -90px;
    /* margin-bottom: 120px; */
}

.iconBadge {
    display: inline-block;
    height: 28px;
    margin-bottom: 5px;
    border-radius: 4px;
    margin-right: 15px;
}

.titleModal {
    width: auto;

}

.textTitle {
    display: inline-block;
    font-family: NokiaPureText-Light, 'Arial', sans-serif !important;
    /* width: 870px; */
    font-size: 22px !important;
    text-overflow: clip; 
    width: 400px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    border: 1px solid #fff;
    /* margin-left: 13px; */
    text-align: center;
    margin-left: 50px;
}

@media screen and (max-width: 800px) {
    .textTitle {
    /* text-align: center;    */
    margin-left: 10px;
    width: 300px; 
    }

    .buttonReturn{
        margin-left: 365px;
        margin-top: -280px;  
        width: 20px;
    }


  }

.imagePrint {
    display: inline-block;
    width: 400px;
}

.centerPrint {
    text-align: center;
    margin-bottom: 20px;
}

#QRCode {
    width: 50%;
    height: 50%;
    object-fit: contain;
}

/* .modal{
  position: relative;
  top: 50%;
  width: 40%;
  height: 80%;
  margin: auto;
  transform: translateY(-50%);
  ground: #fff;
  padding: 15;
  z-index: 99999999;
  border-radius: 2px;
} */
/* .itemTitle{
  ground: orange;
  padding: 10px;
  font-size: 130%;
  border-radius: 2px 2px 0px 0px;
  color: #fff;
  font-weight: 300;
}
.itemClose{
  float: right;
  margin-top: 1px;
}
.itemIcon img{
  width: 180px;
  margin-top: 10px;
} */
/* .itemLaunch{
  display: block;
  float: right;
  margin-top: -95px;
  /* 
  border: 1px solid orange;
  padding: 6px 35px;
  color: #333;
  text-decoration: none; 
} */
/* .itemLaunch:hover{
  /* color: #fff;
  ground: orange; 
} */
.itemFields {
    padding: 5px 15px;
    height: auto;
    height: 100%;
    overflow: auto;

}

.itemField {
    word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 10px;
    font-size: 90%;
    font-family: NokiaPureText-Regular, 'Arial', sans-serif !important;
    
}

.styleContact {
    margin-top: 10px;
}


.itemFieldBody *  {
    font-family: NokiaPureText-Regular, 'Arial', sans-serif !important;
    /* font-size: 13.5px !important;
    background-color: white !important; */
}


.itemField ul {
    margin-left: 20px;
}

.itemField iframe {
    width: 100%;
}

.itemFieldTitle {
    color: #124191;
    margin-bottom: 5px;
    margin-top:  10px;
    font-size: 110%;
}

.collectionLink {
    margin-top: 5px;
    font-family: NokiaPureText-Regular, 'Arial', sans-serif !important;
    /* font-size: 85%; */
    border-color: #124191;
    color: #124191;
}

.lastModified {
    float: left;
    margin-bottom: 10px;
    color: orange;
    vertical-align: middle;
}

.box {
    float: left;
    margin-bottom: 20px;
    width: 150px;
    margin-right: 120px;
}
.tableContent {
    color: #096dd9;
    /* margin: 10px 0 0 60px; */
    cursor: pointer;
    font-size: 0.9em;
}

.ant-notification {
    z-index: 2000000;
}

.printB{
    border-radius: 0px !important;
    border-color: #124191 !important;
    color: #124191 !important;
    display: block;
    margin-bottom: 120px;
}

.printB:hover{
    background-color: #124191 !important;
    color: #ffffff !important;
}

@media screen and (max-width: 376px){
    .ant-modal-footer .ant-btn{
        font-size: 0.6em !important;
        padding: 3px;
    }

    .ant-modal-footer{
        text-align: center;
    }
}
