
.ask {
  position: fixed;
  bottom: 3%;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  z-index: 1;
  box-shadow: 3px 3px 15px 2px #848282;
}
.ask:hover {
  border:2px solid #555; 
}