/*************************************************************************************
	#leadership {   
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%; 
	font-family: NokiaPureText-Regular, Arial, sans-serif;
	}
*************************************************************************************/
.ant-divider-horizontal.ant-divider-with-text:before, .ant-divider-horizontal.ant-divider-with-text-left:before, .ant-divider-horizontal.ant-divider-with-text-right:before, .ant-divider-horizontal.ant-divider-with-text:after, .ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-horizontal.ant-divider-with-text-right:after {
    border-top: 2px solid #00000069;
}

.containerImg {
    float: left;
    width: 260px;
    height: 120px;
    margin: 3px;
    background: #bec8d2;
    box-sizing: border-box;
    padding: 0%;
}

.containerImg img {
    width: 100%;
    height: 100%;
}

.containerDark {
    float: left;
    width: 260px;
    height: 120px;
    margin: 3px;
    background: #eae9e8;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #003a59
}

.containerYell {
    float: left;
    width: 260px;
    height: 120px;
    margin: 3px;
    background: #eae9e8;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #f1c911
}

.containergrey {
    float: left;
    width: 260px;
    height: 120px;
    margin: 3px;
    background: #eae9e8;
    box-sizing: border-box;
    padding: .3%;
    border-left: 24px solid #A6A6A6
}

p.cfoTitles {
    margin: 3px 0px;
    font-size: 16px;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}

p.cfoDelivery {
    margin: 10px 0px;
    font-size: 11px;
    color: #001135;
    font-family: NokiaPureText-Light, Arial, sans-serif;
}