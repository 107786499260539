.topicSection{
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
    display: grid;
}

.topicSection * {
    font-family: NokiaPureText-Regular, 'Arial', sans-serif !important ;
    font-size: 14px ;
}


.itemFieldBody *  {
    font-family: NokiaPureText-Regular, 'Arial', sans-serif !important;
    /*font-size: 13.5px !important;
    background-color: unset !important; */
}

@media screen and (min-width: 300px) {
    .itemFieldBody {
      margin-left: 10px;
      margin-right: 40px;
    }
  }


  @media screen and (min-width: 300px) {
    .itemFieldTitle {
      margin-left: 10px;
      margin-right: 40px;
    }
  }
  

.titleSection{
    background-color: white;
    margin-top: 60px;
    padding: 20px;
    width: 100%;
}


.titleBox {
    /* display: inline-block; */
    position: relative;
}


.titleText {
    position: absolute;
    width: 100%;
    top: 35%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}

.main{
    height: 400px;
}

.contain{
    display: inline-block;
    width: 100%;
}

.text{
    vertical-align: top;
    margin-bottom: 10px;
    /* width: 80%; */
}

.objects{
   margin-top: 1px;
}

.storeTopic{
    padding-top: 70px;
    margin-left: 15%;
    margin-right: 15%
}

.topicTitle{
    justify-content: center;
    /* margin-left: 43px; */
}

.desc2 li{
    margin-left: 4%;
}

.text li{
    margin-left: 4%;
}

.topicDesc{
    margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
    .topicDesc {
      margin-left: 20px;
      margin-right: 20px;
      /* text-align: center; */
    }
  }

.tableButtonT{
    position: relative;
 
    width: 100%
}

.tableSection{
    padding: 10px 0px 0px 0px !important;
}

.tableIcon{
    filter: brightness();
}

.text ul > li{
    margin-left: 25px;
}

.collapseTopic{
    /* width: 73%; */
    margin-bottom: 30px;
    /* margin-left: 40px; */
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
}

.collapseTopic  .ant-collapse-content{
    background-color: #f7f7f7 !important;
}

.sectionTitle {
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 21px;
}


.buttonBoxTopic {
    background-color: rgb(241, 228, 228);
}

.collapseB {
    margin: 0px 10px 10px 0px;
    border-radius: 0px !important;
    border-color: #124191 !important;
    color: #124191 !important;
}


@media screen and (max-width: 800px) {
    .collapseB {
      margin-left: 10px;
    }
  }

.cartButtonTopic {
    float: right;
    text-align: left !important;
}

.printButtonTopic {
    float: right;
    margin-right: unset;
    width: 140px !important;
    text-align: left !important;
}

.seqItem{
    position: relative;
    left: 190px;
    top: -240px;
    display: inline-block;
    border-radius: 50%;
    background-color: #124191;
    color: white;
    z-index: 10;
    width: 22px;
    text-align: center;
}

.tagline{
    font-size: 18px;
    font-weight: bold;
    opacity: 1;
}

.tagLineBackground {
    background-color: rgba(255, 255, 255, .4);
    padding-top: 3px;
    padding-bottom: 3px;
}

.topicPageTitle {
    font-size: 28px;
    color: white;
    font-weight: bold;
    padding-bottom: 10px;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}


@media screen and (max-width: 600px) {
    .topicPageTitle {
        font-size: 21px;
    }
    .storeTopic{
        padding-top: 70px;
        margin-left: 0%;
        margin-right: 0%
    }

    .tagline{
        display: none;
    }
}

