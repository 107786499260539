
.store {
    padding-top: 100px;
    padding-bottom: 70px;
    background-color: #ffffff;
}

.catTitle {
    font-size: 115%;
    color: #005AFF;
}

.ant-tree {
    margin: 0px 10% 5% 10%;
    background-color: #ffffff;
    border: 1px solid lightgrey;
}

.styleTabs{
    margin-top: 15px;
}

.ant-tree li{
    border-top: 1px solid lightgrey;
}

.paraph{
    margin: 0px 10% 5% 10%;
    padding: 30px;
    background-color: #ffffff;
    border-bottom: 3px solid #124191;
}

.collapseB{
    margin: 0px 0px 30px 10%;
    border-radius: 0px !important;
    border-color: #124191 !important;
    color: #124191 !important;
    z-index: 1;
}

.collapseB:hover{
    background-color: #124191 !important;
    color: #ffffff !important;
}

.Box{
    background-color: #F3F3F3;
    text-align: right;
    vertical-align:middle;
}

.Title{
    text-align: center;
    margin-top: 25px;
}

.Box_Counter{
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    background-color: #005AFF;
    /* width:67%; */
    /* margin-left: 310px; */
    height: 150px;
    padding-top: 25px;
    /* padding-left: 45px; */
    text-align: center;
    word-break: normal;
    /* padding: auto; */
    margin: auto auto 50px auto;
    
  
}

.Box_nbr{
    /* background-color: #fff; */
    /* font-family: nokiapure_hregular; */
    margin-top: 10px;
    margin-right: 70px;
    margin-left: 20px;
    color: white;
    font-size: 38px;
}

.sous_title{
    margin-right: 70px;
    margin-left: 20px;
    color: white;
    font-size: 14px;
}

.linkStyle{
    color: black;
    font-size: 12px;
    vertical-align: middle;
    padding-right: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.linkStyle:hover{
    text-decoration: underline; 
}

 @media screen and (min-width: 300px) {
    .catTitle {
      margin-left: 25px;
    }
  }