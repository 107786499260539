.slider {
    position: relative;
    z-index: 0;
    animation: coverflow 4s ease both infinite;
}


.slider img {
    width: 100%;
    height: 100%;   
}

.imagehint{
    position: fixed;
    font-size: xx-small;
    font-style: italic;
    margin-left: 5px;
    color: white;
}

 