.container {
    vertical-align: middle;
  }
  
  .calendar{
    margin: 10px;
    height: 900px;
  }
  
  .rbc-event-content a{
    color: white !important;
  }
  
  .rbc-event-content a:hover{
    color: white !important;
  }
  
  .appName{
    position: absolute;
    top: 18px;
  }
  
  .table{
    margin: 100px 100px 200px 100px;
  }
  
  .calendarCollapseB{
    margin: 0px 10px 30px 10px !important;
    width: 100px !important;
  }
  
  .week{
    font-size: 25px;
  }