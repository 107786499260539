.thumbnailHolder{
    background-color: transparent;
    width: 250px;
    height: 250px;
    perspective: 1000px;
}


.thumbnailHolder .thumbnail {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    font-family: NokiaPureText-Regular !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-color: lightgray; 
    border-width:thin;

}

.thumbnailBack{
    color:#fff;
}

.thumbnailHolder .thumbnailBack {
    position: relative;
    background-color: lightsteelblue;
    transition: opacity 0.380s ease-in;
    opacity: 0;
}

.thumbnailHolder:hover .thumbnailBack {
    opacity: 1
}  

.thumbnailFace { 
    position: absolute;
    background: #fff;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events:none;
}


.wip {
    border: 2px dashed orange;
}

.thumbnailLogo {
    width: 250px;
    height: 125px;
    border-bottom: 1px solid lightgray;
    border-width: thin;
    text-align: center;
}

.thumbnailLogo img {
    max-width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 125px;
    object-fit: contain;
}

.thumbnailTitle {
    padding: 10px;
    font-size: 14px;
    color: #383838;
    overflow: hidden;
}

.thumbnailDescription {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    color: #383838;
    overflow: hidden;
    font-size: 13px !important;
}


.thumbnailGreyTitle {
    position: absolute;
    padding: 10px;
    color: #383838;
    font-size: 13px;
    bottom: 0px;
    right: 0px;
    font-size: 13px !important;
}

.thumbnailSubTitle {
    padding-left: 10px;
    padding-right: 10px;
    color: #383838;
    font-size: 13px !important;
}


.thumbnailLaunchText {
    position: absolute;
    padding: 10px;
    font-size: 13px !important;
    bottom: 25px;
    right: 0px
}

.NokiaLearnLaunchText {
    position: absolute;
    padding: 10px;
    font-size: 13px !important;
    bottom: 25px;
    left: 0px
}

.thumbnails {
    margin-top: 25px;
    margin-bottom: 50px;
}

.imgBlur {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.iconThumb{
    width: 30px;
    height: 30px;
    min-width: 30px;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .thumbnails {
      margin-left: 60px;
    }
  }



