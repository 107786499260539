#edit {
    /* border-top: 1px solid rgb(206, 206, 206); */
    /* border-bottom: 1px solid rgb(206, 206, 206); */
    display: block;
    margin-top: 5px;
    background-color: transparent;
}

.containerEdit {
    padding-right: 20px;
    padding-left: 20px;
    background-color: orange;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.containerEditItem {
    padding-right: 4px;
    padding-left: 4px;
    background-color: orange;
    border-radius: 4px;
    border-width: 10px;
    margin-bottom: 40px;
    padding-top: 1px;
}

#editItem {
    display: block;
    margin-top: 5px;
    background-color: #fff;
}

.navigationEdit .rc-menu {
    border: none;
    color: white;
    background-color: orange;
}

.rc-menu-horizontal > .rc-menu-item, .rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 10px 18px;
}

.rc-menu-vertical > .rc-menu-item, .rc-menu-inline > .rc-menu-item, .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 10px 30px 10px 20px;
}

.navigationEdit .rc-menu-submenu, .navigationEdit .rc-menu-item {
    cursor: pointer;
}

.navigationEdit .rc-menu-item {
    /* border-bottom: 2px solid transparent; */
}

.navigationEdit .rc-menu-submenu-active, .navigationEdit .rc-menu-item-active {
    border-bottom: 2px solid orange;
    color: orange;
    background-color: #fff;
}

.navigationEdit .rc-menu-submenu-active .rc-menu-submenu-title {
    background-color: #fff;
}

.navigationEdit .rc-menu-item-selected, .navigationEdit .rc-menu-submenu-selected {
    background-color: #fff;
    color: orange;
}

.rc-menu-submenu {
    border-bottom: 2px solid #fff;
}

.chevron {
    margin-bottom: 2px;
    margin-left: 3px;
}

.ant-badge-not-a-wrapper .ant-scroll-number {
    top: -10px;
}

.ant-badge-count {
    line-height: 14px;
    margin-left: 5px;
    font-size: 10px;
    height: 14px;
    background: #db2828;
}

.divide{
    border-bottom: 2px dashed white;
}

.editButton{
    position: fixed;
    bottom: 130px;
    right: 40px;
    width: 60px !important;
    height: 60px !important;
    background-color: #ffa500;
    border-color: #ffa500;
    z-index: 99999;
}


.editButton:hover{
    background-color: rgb(255, 179, 38);
    border-color: rgb(255, 179, 38);
}

.editButton:focus{
    background: #ffa500 !important;
    border-color: #ffa500;
}

.editButtonSearch{
    padding-right: 15px;
    position: fixed;
    top: 70px;
    right: 10px;
    width: 40px !important;
    height: 40px !important;
    background-color:#005AFF;
    z-index: 99999;
}


.editButtonSearch:hover{
    background-color: #0049B4;
    border-style: none;
}

.editButtonSearch:focus{
    background: #0049B4 !important;
    border-style: none;
}

.del{
    position: fixed;
    bottom: 200px;
    right: 40px;
}

.tool{
    z-index: 9999999 !important;
}