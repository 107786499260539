.slick-prev, 
.slick-next{

    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    display: block;

    width: 35px;
    height: 35px;

    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;
    z-index: 100;

    color: transparent; 
    border: none;
    outline: none;
    background:transparent;

    background-color: #f1f1f1;
    border-radius: 50%;

}

slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
   /* color: transparent;
    outline: none;
    background: transparent; */
    background-color: #ddd;
    color: black;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 25px;
    line-height: 1;

    opacity: 1;
    color:darkgrey;


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next
{
    right: 0;


}

.slick-prev:before
{
    content: '<'; 

}

slick-prev
{
    left: 0;
}

.slick-next:before
{
    content: '>';
}