.searchEngine{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0% 8% 0% 8%;
    justify-content:stretch;
}

@media screen and (max-width: 800px) {
    .searchEngine {
        flex-direction: column;
        width: 400px;
        grid-gap: 10px;
       }
  }

.resultsGrid{
    padding: 0% 8% 0% 8%;
    margin-top: 25px;
}

/* .advSearchInput{
    width: 40%;
} */

.advSearchSelect{
    width: 35%;
}

.advResults{
 padding: 50px !important;
}

.tableResults{    
   /* margin: 4.4%; */
    padding: unset !important;
}



.loadMore{
    padding: 0% 8% 0% 10%;

}

.moreRslt{
    margin: 30px 0 ;
}

@media screen and (max-width: 1400px){
    .resultsGrid{
        padding: 0% 12% 0% 12%;
        margin-top: 25px;
        margin-left: 30px;
    }
    .searchEngine{
        padding: 0% 12% 0% 12%;
    }
    .loadMore{
        padding: 0% 8% 0% 15%;
    }
}