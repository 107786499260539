body {
    margin: 0;
    padding: 0;
}

/*

  For the Partner Store Home Page
  
*/

#P-news p {
    border-bottom: 1px solid #fff;
    margin-bottom: 4%;
}

#P-news a {
    color: #fff;
    text-decoration: none;
    line-height: 1.5em;
}

#P-news a:hover {
    text-decoration: underline gray;
    color: lightgrey;
}

.P-all {
    display: inline-block;
    width: 31%;
    margin-right: 1%;
    margin-left: 1%;
    background: #124191;
    /* border-radius: 10px; */
    padding: 10px;
    color: #fff;
    height: 100%;
    vertical-align: top;
    margin-top: 20px;
    padding-bottom: 5999px;
    margin-bottom: -5999px

}

@media (max-width: 550px) {
    .P-all {
        display: inline-block;
        width: 100%;
        background: #124191;
        margin: 0;
        /* border-radius: 10px; */
        padding: 10px;
        color: #fff;
        vertical-align: top;
        margin-top: 20px;
    }
}

@media (max-width: 850px) {
    .P-all {
        display: inline-block;
        width: 48%;
        background: #124191;
        margin-bottom: 0;
        /* border-radius: 10px; */
        padding: 10px;
        color: #fff;
        vertical-align: top;
        margin-top: 20px;
    }
}

@media (max-width: 550px) {
    .P-all {
        display: inline-block;
        width: 100%;
        background: #124191;
        margin: 0;
        /* border-radius: 10px; */
        padding: 10px;
        color: #fff;
        vertical-align: top;
        margin-top: 20px;
    }
}

.P-container {
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.card-ibm {
    float: left;
    height: 110px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    color: #5f6368;
    font-size: 14px;
    margin: 20px 5px;
    overflow: hidden;
    transition: box-shadow 135ms cubic-bezier(.4,0,.2,1),width 235ms cubic-bezier(.4,0,.2,1);
    vertical-align: top;
    width: 240px;
    z-index: 1;
    box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16);
}
.card-ibm:hover {
    background: whitesmoke;
}

.p-ibm {
    margin: -40px 0 0 50px;
}
