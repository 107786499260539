html {
    overflow-y: scroll;
}

.top {
    margin-top: 100px;
}

.editLabel {
    font-size: 110%;
    width: 18%;
    float: left;
    color: darkorange;
    padding-left: 10px;
    margin-top: 10px;
}

#form {
    margin-top: 150px;
    margin-bottom: 40px;
}

.quill > div {
    border-color: #E6E6E6 !important;
}

.wrapperEdit {
    width: 700px;
    margin: auto;
}

.edit_box {
    background: #fff;
}

.editField {
    width: 80%;
    background-color: #F9F9F9;
    border-style: solid;
    padding: 10px 5px;
    border-color: #E6E6E6;
    border-width: 1px;
    outline-style: none;
    margin-top: 10px;
}

.dateField{
    width: 100%;
    background-color: #F9F9F9;
    border-style: solid;
    padding: 10px 5px;
    border-color: #E6E6E6;
    border-width: 1px;
    outline-style: none;
    margin-top: 10px;
}

.react-datepicker-wrapper{
    width: 80% !important;
}

.editFieldDisabled {
    background-color: rgb(230, 230, 230);
    color: rgb(156, 156, 156);;
}

.item {
    background-color: #FFFFFF;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    outline-style: none;
}

.editFlow {
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 10px;
}

.RTE {
    margin-left: 18%;
    width: 80%;
    /* border-style: solid;
    border-color: lightgray;
    border-width: 1px; */
}

.editSave {
    margin-left: 18% !important;
    margin-bottom: 30px !important;
    margin-top: 40px !important;
    border: 0;
    background-color: darkorange;
}

.editSave:hover {
    border: 0;
    background-color: orange;
}

.editSave:focus {
    border: 0;
    background-color: orange;
}

.listfield {
    overflow: hidden;
}

.delButton {
    cursor: pointer;
    float: right;
    margin-right: 80%;
    margin-top: -24px;
}

.addButton {
    cursor: pointer;
    margin-left: 25%;
}

.toggleWip {
    margin-top: 10px;
}

.ant-switch-checked {
    background-color: darkorange !important;
}

.selectItems {
    /* border: 1px solid #E6E6E6; */
    padding: 5px;
    margin-right: 8px;
    margin-left: 7px;
    max-height: 300px;
    overflow: auto;
}

.selectItem {
    background: #efefef;
    margin-top: 3px;
    cursor: pointer;
    padding: 4px;
}

.selectItem:hover {
    background: rgb(223, 223, 223);
}

.selectMore {
    background: #f2711c30;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 4px;
    text-align: center;
}

.selectedItems {
    padding: 12px;
}

.selectedItemsEmpty {
    text-align: center;
    color: #666;
}

.dropzone {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
}

.dropzone div {
    padding: 5px;
    width: 150px !important;
    height: 170px !important;
    padding-top: 35px;
    text-align: center;
}

.dropzone div:hover {
    cursor: pointer;
    border-style: solid !important;;
}

.dropzoneimage {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    margin-left: 15px;
    width: 395px;
}

.removeImage {
    display: inline-flex;
    align-items: baseline;
}

.dropzoneUrlField {
    width: 373px;
    background-color: #F9F9F9;
    border-style: solid;
    padding: 10px 5px;
    border-color: #E6E6E6;
    border-width: 1px;
    outline-style: none;
    margin-top: 10px;

}

.dropzoneTitle {
    font-size: 110%;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 8px;
}

.dropzonePreview {
    width: 120px;
    display: block;
    margin-top: 20px;
}

.imgPreview{
    display: inline-block;
    margin-right: 10%;
}

.linkIcon{
    font-size: 14px;
    font-weight: bold;
}

.buttonAdd{
    width: 300px;
    margin-top: 20px !important;
    line-height: normal;
}

.explain{
    margin: 10px;
    vertical-align: -webkit-baseline-middle;
    display: inline-block;
    width: 60%;
}

.delicon{
    display: inline-block;
    position: relative;
    left: 97%;
    top: 3px;
}

.typeIcon{
    display: inline-block;
    position: relative;
    left: 95%;
    top: 3px;
}

.selectItemTitle{
    display: inline-block;
}

.sectionModal{
    width: 800px !important;
}

.buttonModal{
    margin-right: 20px !important;
    margin-bottom: 30px !important;
    border: 0;
    background-color: darkorange;
    width: 160px;
}

.buttonModal:hover{
    border: 0;
    background-color: orange;
    color: white
}

.buttonModal:focus {
    border: 0;
    background-color: orange;
}

.buttonGroup{
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 16px;
}

.sortEltEdit{
    width: 100%;
    height: 50px;
    background: #efefef;
    margin-top: 7px;
    cursor: pointer;
    padding-top: 1%;
    /* padding-bottom: 5%; */
    font-size: 15px;
    font-weight: bold;
    padding-left: 10px;
    border-radius: 10px;
}



.selectedSectionTitle{
    margin-top: 0px; 
    display: inline-block;
}

.iconModal{
    margin-right: 20px !important;
    margin-bottom: 30px !important;
    border: 0;
    display: inline-block;
}

.iconModal:hover{
    cursor: pointer;
}

.tagList{
    display: inline-block !important;
    width: 80%;
    margin-top: 10px;
}

.ant-tag-checkable{
    color: orange;
}

.ant-tag-checkable-checked{
    background-color: orange;
    color: white;
}

