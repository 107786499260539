.loadMore {
    margin-top: 10px;
    cursor: pointer;
    color: #1890ff;
}

#iconSearchNF {
    font-size: 200px;
}

#iconSearchP {
    margin-top: 10%;
    text-align: center;
}