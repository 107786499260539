.auditTable table{
    font-size: 1.3em;
    margin-top: 30px;
}

.auditTable th{
    font-size: 1.6em;
}

.auditTable .ant-table-row-expand-icon{
    font-size: 0.7em;
} 

.auditTable .ant-table-row-collapsed{
    font-size: 0.7em;
}

.auditTable ul {
    font-size: 0.8em;
}

.auditTable{
    width: 80%;
    margin-left: 10%;
}

.filterTable{
    position: absolute !important ;
    right: 10px !important;
    top: 30px !important;
}
