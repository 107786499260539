.ask {
    position: fixed;
    bottom: 2%;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
}
.ask-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
}
.ant-popover-arrow {
    display: none;
 }
 .ant-popover-inner-content {
    padding: 5px;
}
.ant-popover-content {
    margin-right: 10px;
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
}
.ant-modal-content {
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 17px !important;
}
.ant-popover-title {
    background: #15418f;
    color: #FFF;
}
p {
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
}