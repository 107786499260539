body {
    background-color: #FFFFFF;
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 17px;
  }
  
  .container {
    vertical-align: middle;
  }
  
  .navButton{
    margin-left: 10px;
    color: white!important;
    
    border-radius:0px!important;
  }
  
  .navButton:hover{
    margin-left: 10px;
    background-color: #ffffff!important;
    border-color: #ffffff!important;
    color: #124192!important;
    border-radius:0px!important;
    text-align: center;
  }
  
  
  .imgb{
    display: inline-block;
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    background-color: rgb(240, 238, 238);
    border: none;
    margin: 0.7em;
    /* padding: 1em; */
    -webkit-transition: all 0.3s ease-out; /*compatibilité chrome*/
     -moz-transition: all 0.3s ease-out; /*compatibilité firefox*/
     -o-transition: all 0.3s ease-out; /*compatibilité opéra*/
     transition: all 0.3s ease-out; /* Autre navigateur */
  }
  
  .imgb:hover{
    background-color: #2261ce;
    cursor: pointer;
    display: inline-block;
    color: #ffffff;
  }
  
  .filterbar{
    background-color:  #124192;
    
    margin-bottom: 3em;
    width: 100%;
    padding:5px;
  }
  
  .pullRight{
    float: right;
    /* margin-top: 8px; */
    margin-right: 10px;
  }
  
  .label{
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 18px;
    color: #ffffff;
  }
  
  .pure {
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
  }
  .middle {
    vertical-align: middle;
  }
  
  .image{
    width: 200px;
    max-height: 100px;
    padding: 2px 2px 2px 2px;
  }
  
  .images{
    text-align: center;
    margin-top: 60px;
    margin-bottom: 90px;
  }
  

  
  .ant-tag {
    /* margin-right: -5px !important;
    margin-left: 10px !important; */
    margin-right: 10px;
  }
  
  .active{
    background-color: #ffffff !important;
    color: #124192 !important;
    border-color: #ffffff !important;
  }
  
  @font-face {
    font-family: NokiaPureText-Light;
    /* src: url('./NokiaPureText-Light.eot'); */
    src: url('NokiaPureText-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }