@import '../node_modules/antd/dist/antd.css';

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    word-break: normal;
}

@media (max-width: 600px) {
    .hidenOnSmall {
        display: none !important;
    }
}

#editDimmer {
    opacity: 0;
    background: black;
    color: white;
    padding: .5em;
    display: none;
    width: 100%;
    position: absolute;
    font-weight: bold;
    /* transition: visibility 1s, opacity 0.5s linear; */
    top: 0px;
    z-index: 9999999999999;
    height: 100%;
    text-align: center;
    font-size: 200%;
    max-height: 100vh;
}

#editDimmer > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#editDimmer img {
    width: 100px;
    margin-bottom: 40px;
}

html {
    overflow-y: initial !important;
}

html, body {
    height: 100%;
}

html body #root, html body #root .home {
    height: 100%;
}

.image {
    object-fit: contain;
    width: 100%;
    padding: 15px;
}
.pointer {
    cursor: pointer;
}

.inline {
    display: inline;
}

.noMarginRight {
    margin-right: 0px !important;
}

body {
    background-color: #ffffff;
    font-family: NokiaPureText-Light, 'Arial', sans-serif;
    font-size: 17px;
}

.thumbnails{
    margin-bottom: 60px !important;
}

.floatRight {
    float: right !important;
}

.floatLeft {
    float: left !important;
}

.bold {
    font-weight: bold;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.minHeight {
    min-height: 100vh !important;
    position: relative;
}

.modalText {
    max-height: 400px;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px
}

.loading {
    text-align: center;
    line-height: 100vh;
    width: 100%;
}

.foot{
    border: none;
}

@font-face {
    font-family: NokiaPureText-Light;
    /* src: url('./NokiaPureText-Light.eot'); */
    src: url('css/NokiaPureText-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: NokiaPureText-Regular;
    /* src: url('./NokiaPureText-Regular.eot'); */
    src: url('css/NokiaPureText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
